<template>
  <div id="Applicants">
    <Applicants-Page />
    <casid-detail />
  </div>
</template>

<script>
import ApplicantsPage from "../components/ApplicantsPage";
import casidDetail from "./casidDetail";

export default {
  name: "Applicants",

  components: {
    ApplicantsPage,
    casidDetail,
  },
};
</script>

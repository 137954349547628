<template>
  <div>
    <v-container fluid class="grey lighten-5">
      <v-row dense>
        <v-col>
          <v-card class="mx-auto">
            <v-list-item>
              <v-list-item-content>
                <v-row dense>
                  <v-col class="xs" cols="12" sm="6">
                    <v-text-field
                      v-model="mROWDATA.COMPANY_NAME"
                      label="상호명"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="6" sm="3">
                    <v-text-field
                      v-model="mROWDATA.TEL_NUMBER"
                      label="전화번호"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="12" sm="3">
                    <v-text-field
                      v-model="mROWDATA.ADDRESS1"
                      label="주소1"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="12" sm="5">
                    <v-text-field
                      v-model="mservice1"
                      label="기본서비스"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="12" sm="5">
                    <v-text-field
                      v-model="mservice2"
                      label="선택서비스"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="12" sm="2">
                    <v-text-field
                      v-model="mROWDATA.STATUS"
                      label="상태코드"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="6" sm="3">
                    <v-text-field
                      v-model="mROWDATA.REG_DATE"
                      label="등록일자"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="6" sm="3">
                    <v-text-field
                      v-model="mROWDATA.REG_TIME"
                      label="등록시간"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="6" sm="3">
                    <v-text-field
                      v-model="mROWDATA.CHG_DATE"
                      label="변경일자"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="6" sm="3">
                    <v-text-field
                      v-model="mROWDATA.CHG_TIME"
                      label="변경시간"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="xs" cols="6" sm="2">
                    <v-text-field
                      v-model="mROWDATA.RESPCODE"
                      label="수신코드"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="12" sm="10">
                    <v-text-field
                      v-model="mROWDATA.RESPMSG"
                      label="수신메세지"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="6" sm="3">
                    <v-text-field
                      v-model="mROWDATA.CPID"
                      label="CASID"
                      outlined
                      background-color="#f5f5dc"
                      readonly
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="6" sm="3">
                    <v-text-field
                      v-model="mROWDATA.SUB_NO"
                      label="승인번호"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="12" sm="6">
                    <v-row justify="center" align="end" dense>
                      <v-col class="xs" cols="12">
                        <v-btn
                          color="success"
                          elevation="1"
                          class="white--text mx-5"
                          @click="onRequestAuth"
                        >
                          승인요청
                        </v-btn>

                        <v-btn color="success" elevation="1" @click="onOkAuth">
                          승인정상처리
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="xs" cols="6" sm="3">
                    <v-text-field
                      v-model="mROWDATA.ADMIN_NAME"
                      label="이름"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col class="xs" cols="12" sm="9">
                    <v-text-field
                      v-model="mROWDATA.ADDRESS2"
                      label="상세주소"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea
                      ref="rMessage"
                      v-model="mMessage"
                      label="접수 내용"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn
                class="mr-1"
                color="success"
                elevation="4"
                @click="onResetAdvice"
              >
                접수 취소
              </v-btn>
              <v-btn
                class="mr-5"
                color="success"
                elevation="4"
                @click="onOkAdvice"
              >
                접수 완료
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventBus from "@/components/EventBus";

import { mapActions, mapGetters } from "vuex"; // mapGetters를 추가한다

const userStore = "userStore";
const casezi = "casezi";

export default {
  name: "TestPage",
  data() {
    return {
      mROWDATA: {},
      mcasid: "",
      msubno: "",
      mMessage: "",
      mservice1: "",
      mservice2: "",
    };
  },
  watch: {},
  created() {
    EventBus.$on("push-app-row", (payload) => {
      this.mcasid = payload.CPID;
      this.msubno = payload.SUB_NO;
      this.onSubmit();
      this.onGetAdvice();
    });
    EventBus.$on("push-app-rowreset", () => {
      this.mROWDATA = {};
      this.mMessage = "";
    });
  },
  methods: {
    ...mapActions(userStore, [
      "GET_DATA",
      "insertAdvice",
      "selectAdvice",
      "deleteAdvice",
      "RequestAuth",
      "OkAuth",
      "getService1",
    ]),
    ...mapGetters(userStore, {
      service1: "GE_SERVICE1",
      service2: "GE_SERVICE2",
    }),
    ...mapActions(casezi, ["REQU_CASEZI"]),
    async onSubmit() {
      try {
        const getResult = await this.GET_DATA({
          fun_code: "rselect",
          cas_id: this.mcasid,
          sub_no: this.msubno,
        });
        if (getResult.data.repCode == "00") {
          console.log("로그인 결과", getResult);
          this.mROWDATA = getResult.data.repData;
          this.mservice1 = this.service1()[this.mROWDATA.SERVICE1 - 1].state;
          this.mservice2 = this.service2()[this.mROWDATA.SERVICE2 - 1].state;
        } else {
          console.log("로그인 99 결과", getResult);
          this.mROWDATA = getResult.data.repData;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onGetAdvice() {
      try {
        const getResult = await this.selectAdvice({
          fun_code: "select",
          cas_id: this.mcasid,
          sub_no: this.msubno,
        });
        if (getResult.data.repCode == "00") {
          console.log("로그인  결과", getResult);
          this.mMessage = getResult.data.repData.advice_desc;
        } else {
          //  console.log("service 결과", this.service()[1 - 1].state);
          console.log("로그인 99 결과", getResult);
          this.mMessage = getResult.data.repData;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onOkAdvice() {
      try {
        const getResult = await this.insertAdvice({
          fun_code: "upset",
          cas_id: this.mcasid,
          sub_no: this.msubno,
          advice_desc: this.mMessage,
          admin_name: this.mROWDATA.ADMIN_NAME,
          address2: this.mROWDATA.ADDRESS2,
        });
        if (getResult) {
          console.log("edit 로그인 결과", getResult);
          this.onReset();
          this.modifyAdvice();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onResetAdvice() {
      try {
        const getResult = await this.deleteAdvice({
          fun_code: "delete",
          cas_id: this.mcasid,
          sub_no: this.msubno,
        });
        if (getResult) {
          console.log("edit 로그인 결과", getResult);
          this.mMessage = "";
          this.onReset();
          this.modifyAdvice();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onRequestAuth() {
      try {
        const Result = await this.REQU_CASEZI({
          fun_code: "ReqCasezi",
          cas_id: this.mcasid,
          sub_no: this.mROWDATA.SUB_NO,
          old_sub_no: this.msubno,
          admin_name: this.mROWDATA.ADMIN_NAME,
          address2: this.mROWDATA.ADDRESS2,
        });
        if (Result) {
          this.msubno = this.mROWDATA.SUB_NO;
          console.log("로그인 결과", Result);
          this.onReset();
          this.modifyAdvice();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onOkAuth() {
      try {
        const getResult = await this.OkAuth({
          fun_code: "OkCasezi",
          cas_id: this.mcasid,
          sub_no: this.msubno,
        });
        if (getResult) {
          console.log("edit 로그인 결과", getResult);
          this.onReset();
        }
      } catch (err) {
        console.error(err);
      }
    },
    onReset() {
      this.mROWDATA = "";
      this.mMessage = "";
      this.onSubmit();
      this.onGetAdvice();
    },
    modifyAdvice() {
      let payload = { cas_id: this.mcasid, sub_no: this.msubno };
      EventBus.$emit("push-modifyAdvice", payload);
    },
  },
};
</script>

<style scoped>
.table-scrollable {
  overflow-y: scroll;
}
th {
  background-color: #bbdefb;
}
td {
  background-color: #e3f2fd;
}
</style>

<template>
  <div>
    <v-container fluid class="grey lighten-5">
      <v-row class="gutters" align="center">
        <v-col class="text-center elevation-1" cols="12">
          <h2>이벤트 신청 접수</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="grey lighten-5">
      <v-form v-model="valid">
        <v-row align="center" class="grey lighten-5 gutters">
          <v-col class="xs" cols="12" sm="3">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="mREG_DATE"
                  label="접수일자"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="mREG_DATE" @input="menu2 = false" />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2" class="d-flex xs">
            <v-select
              v-model="mRESPCODE"
              :items="items1"
              item-text="state"
              item-value="id"
              label="승인구분"
              required
              @change="populateRespCode(mRESPCODE)"
            />
          </v-col>

          <v-col class="xs" cols="12" sm="3">
            <v-select
              v-model="mSERVICE2"
              :items="items2"
              item-text="state"
              item-value="id"
              label="선택서비스"
              required
              @change="populateService2(mSERVICE2)"
            />
          </v-col>
        </v-row>
        <v-row class="grey lighten-5 ml-2 mr-2">
          <v-col style="overflow: auto">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-col>
          <v-col class="xs align" cols="12" sm="4">
            <v-btn class="mr-5" color="success" elevation="4" @click="onReset">
              초기화
            </v-btn>
            <v-btn class="mr-1" color="success" elevation="4" @click="onSubmit">
              조 회
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container fluid class="grey lighten-5">
      <v-data-table
        :headers="headers0"
        :items="desserts"
        :search="search"
        item-key="SEQ"
        :value="selectedRows"
        class="elevation-1 grey lighten-5"
        @click:row="rowClicked"
      />
    </v-container>
  </div>
</template>

<script>
import EventBus from "./EventBus";

import { mapActions } from "vuex"; // mapGetters를 추가한다

const userStore = "userStore";

export default {
  name: "TestPage",
  data() {
    return {
      valid: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      selected: [],
      selectedRows: [],
      search: "",
      desserts: [],
      date: new Date().toISOString().substr(0, 10),
      menu2: false,
      mREG_DATE: "",
      mRESPCODE: "",
      mSERVICE2: "",
      mROWDATA: {},
    };
  },
  computed: {
    headers() {
      return [
        { text: "CASID", value: "CPID", width: "100px", fixed: true },
        { text: "승인번호", value: "SUB_NO", width: "100px", fixed: true },
        { text: "이름", value: "ADMIN_NAME", width: "100px" },
        { text: "전화번호", value: "TEL_NUMBER", width: "150px" },
        { text: "상호명", value: "COMPANY_NAME", width: "300px" },
        { text: "상담여부", value: "ADV_YN", width: "60px" },
      ];
    },
    headers0() {
      return this.headers.map((header) =>
        Object.assign({}, header, { fixed: false })
      );
    },
    headers2() {
      return [
        { text: "", fixed: true, width: "100px", sortable: false },
      ].concat(this.headers);
    },
    headers3() {
      return [
        { text: "", value: "data-table-expand", fixed: true, width: "10px" },
      ].concat(this.headers);
    },
    headers4() {
      return this.headers.concat([
        { text: "", value: "data-table-expand", fixed: true, width: "10px" },
      ]);
    },
    headers5() {
      return this.headers3;
    },
    items1() {
      var selectAll = [{ state: "전체선택", id: null }];
      return selectAll.concat(this.$store.getters["userStore/GE_RESPCODE"]);
    },
    items2() {
      var selectAll = [{ state: "전체선택", id: null }];
      return selectAll.concat(this.$store.getters["userStore/GE_SERVICE2"]);
    },
  },
  created() {
    EventBus.$on("push-modifyAdvice", (payload) => {
      console.log("로그인 결과", payload);
      this.onSubmit();
    });
  },
  methods: {
    ...mapActions(userStore, ["GET_DATA"]),
    async onSubmit() {
      try {
        const getResult = await this.GET_DATA({
          fun_code: "tselect",
          cas_id: "",
          reg_date: this.mREG_DATE,
          respcode: this.mRESPCODE,
          service2: this.mSERVICE2,
        });
        if (getResult.data.repCode == "00") {
          console.log("로그인 결과", getResult);
          this.mROWDATA = "";
          this.desserts = getResult.data.repData;
        } else {
          console.log("로그인 99 결과", getResult);
          this.mROWDATA = "";
          this.desserts = getResult.data.repData;
        }
      } catch (err) {
        console.error(err);
      }
    },
    onReset() {
      this.mSERVICE2 = "x";
      this.onSubmit();
      this.mREG_DATE = "";
      this.mRESPCODE = "";
      this.mSERVICE2 = "";
      this.mROWDATA = "";
      EventBus.$emit("push-app-rowreset");
    },
    rowClicked(row) {
      console.log(row.SUB_NO);
      EventBus.$emit("push-app-row", row);
    },
    populateRespCode(key) {
      console.log(key);
    },
    populateService2(key) {
      console.log(key);
    },
  },
};
</script>

<style scoped>
.table-scrollable {
  overflow-y: scroll;
}
th {
  background-color: #bbdefb;
}
td {
  background-color: #e3f2fd;
}
</style>
